import "./contact.css";

import React, { useRef, useState } from "react";

import { MdOutlineEmail } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { FormInput } from "../../reusables/FormInput/FormInput";

const Contact = () => {
  const [message, setMessage] = useState(false);
  const formRef = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(true);
    emailjs
      .sendForm(
        "service_pdmiqnq",
        "template_r3qo9m8",
        formRef.current,
        "8KSSmJwUFmp5Fmjbg"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };
  return (
    <section id="contact">
      <h1 className="text-center text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-pink-800 font-serif">
        Get In Touch
      </h1>
      <h5 className="mx-6">
        Please contact to hire me for your website, I do receive your messages
        and will respond asap if the valid email is provided 😊
      </h5>
      {/* <h2>Contact Me</h2> */}
      <div
        className="container contact__container"
        style={{
          marginTop: "1rem",
        }}
      >
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>deepakhatri.kd@gmail.com</h5>
            <a href="mailto:deepakhatri.kd@gmail.com" className="text-[#fff]">
              Send a message
            </a>
          </article>
        </div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <FormInput
            type="text"
            placeholder="Your Full Name"
            name="from_name"
            style={{
              height: "40px",
              padding: "10px",
              color: "white",
            }}
            required={true}
            id="from_name"
          />
          <FormInput
            type="text"
            placeholder="Your Email"
            name="from_email"
            style={{
              height: "40px",
              padding: "10px",
              color: "white",
            }}
            required={true}
            id="from_email"
          />
          <textarea
            placeholder="Your message"
            rows="5"
            name="message"
            style={{
              // height: "40px",
              padding: "10px",
              color: "white",
            }}
            required
          ></textarea>
          <button
            type="submit"
            className="btn btn-pink hover:shadow-lg transition duration-700 ease-in-out"
          >
            Send Message
          </button>
          {message && <span>Thanks, I'll reply ASAP :)</span>}
        </form>
      </div>
    </section>
  );
};

export default Contact;
