import "./intro.css";

import { FaAward } from "react-icons/fa";
import React from "react";
import { VscFolderLibrary } from "react-icons/vsc";
import img from "../../assets/Deepa.jpg";
import dpbow from "../../assets/dpbow.webp";
import ribbon from "../../assets/ribbon.png";

// import img from "../../assets/PortfoliopictureOpt.jpg";
import { useNavigate } from "react-router-dom";
import { TbHandClick } from "react-icons/tb";
import { useProgressiveImg } from "../../hooks/useProgressiveImg";

const Intro = () => {
  const [src, { blur }] = useProgressiveImg(ribbon, img);
  const navigate = useNavigate();
  const handleOnClickProjects = () => {
    navigate("/projects");
  };
  return (
    <div id="about">
      <h1
        style={
          {
            // color: "var(--color-bg-variant)",
          }
        }
        className="text-center text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-pink-800 font-serif"
      >
        Know Me
      </h1>
      {/* <h2>About Me</h2> */}
      <div className="container about__container">
        <div className="about__me bg-pink-600 relative">
          <div className="about__me-image">
            {/* <img src={img} alt="Deepa Khatri" width={10} height={10} /> */}
          </div>
          <div className="w-1/12 h-1/12 absolute right-0 top-0">
            <img src={dpbow} alt="dpbow" />
          </div>
          <div className="w-full h-full about__me-image ">
            <img
              src={src}
              alt="Deepa Khatri"
              loading="eager"
              style={{
                // width: 200,
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.2s ease-out",
              }}
            />
            {/** auto|eager|lazy */}
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>4.5 year</small>
            </article>
            <article
              className="about__card cursor-pointer relative"
              onClick={handleOnClickProjects}
            >
              <TbHandClick className="absolute right-2 top-2 animate-pulse" />
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+ Completed Projects</small>
            </article>
          </div>
          <p>
            4+ years experienced Front End Developer with hands-on experience in
            identifying web-based user interactions along with designing and
            implementing highly-responsive user interface components by
            deploying React concepts. Proficient in translating designs and
            wireframes into high-quality code, and writing application interface
            code via JavaScript and React JS workflows. Adept at monitoring and
            maintaining frontend performance and troubleshooting and debugging
            the same to bolster overall performance.
          </p>
          <a
            href="#contact"
            className="btn btn-pink hover:shadow-lg transition duration-700 ease-in-out"
          >
            Let's Talk
          </a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
