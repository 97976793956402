import React from "react";
import CTA from "./CTA";
// import HeaderSocials from "./HeaderSocials";
import "./header.css";
// import { BsLinkedin } from "react-icons/bs";
import { IoLogoInstagram } from "react-icons/io5";
import { FaAngleDoubleDown } from "react-icons/fa";
import pinkbow from "./../../assets/pinkbow.gif";

const Header = () => {
  return (
    <div id="home">
      <div className="flex justify-start">
        <div className="container header__container">
          <h5 className="mt-3">Hey I'm</h5>
          <h1 className="font-serif text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-pink-800">
            Deepa Khatri
          </h1>
          <h3 className="customTextStyle text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-pink-700">
            Front-end Developer
          </h3>
          <div className="flex justify-center mt-6">
            <a
              href="https://www.instagram.com/ndeepaslife/"
              target="_blank"
              rel="noreferrer"
              // className="header__socials"
              className="text-white gradient rounded-lg"
            >
              <IoLogoInstagram size={22} />
            </a>
          </div>
          <CTA />
          <a href="#contact" className="scroll__down">
            {/* Scroll Down */}
            <FaAngleDoubleDown size={20} color="#f81894" />
            {/* <HeaderSocials /> */}
          </a>
        </div>

        <div className="bg-pink-100"></div>
      </div>
      <div className="flex justify-center">
        <div className="w-1/4 h-1/4">
          <img src={pinkbow} alt="pink-bow-gif" />
        </div>
      </div>
    </div>
  );
};

export default Header;
