import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error("Error", error);

  return (
    <div
      id="error-page"
      className="flex justify-center items-center bg-gray-50 text-pink-500 w-screen h-screen text-3xl"
    >
      <h1>Oops!&nbsp;</h1>
      <p>Sorry, an unexpected error has occurred.&nbsp;</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  );
}
