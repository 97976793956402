import React from "react";
import "../../index.css";
import "./FormInput.css";

export const FormInput = ({ type, placeholder, name, style, required, id }) => {
  return (
    <input
      type={type} //"text"
      placeholder={placeholder} //"Your Full Name"
      name={name} //"from_name"
      style={style} /** {
                height: "40px",
                padding: "10px",
                color: "white",
              } */
      required={required}
      id={id} //"from_name"
    />
  );
};
