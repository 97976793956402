import React from "react";
import {
  Navigate,
  // useNavigate
} from "react-router-dom";

export const ProtectedRoute = ({ comp, isLgn }) => {
  let isLoggedIn = isLgn;
  if (isLoggedIn) {
    return comp;
  } else if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
};
