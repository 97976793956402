import React from "react";
import { TbHandClick } from "react-icons/tb";
import { LuListTodo } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import useWindowSize from "../../hooks/useWindowSize";

export const Projects = () => {
  const [width] = useWindowSize();
  // console.log("width", width);
  const navigate = useNavigate();
  const handleTodoPage = () => {
    navigate("/");
  };
  const handleGoBack = () => {
    navigate("/");
  };
  return (
    <div className="m-8">
      <IoMdArrowRoundBack
        className="cursor-pointer"
        size={28}
        color="#fff"
        onClick={handleGoBack}
      />
      <div
        className={`grid  ${
          width < 900 ? "grid-cols-1" : "grid-cols-2"
        } gap-4 mt-4`}
      >
        <article
          className={`${
            width < 600 ? "" : "w-2/3"
          } about__card  cursor-pointer relative `}
          onClick={handleTodoPage}
        >
          <TbHandClick className="absolute right-2 top-2 animate-pulse " />
          <div className="flex flex-row">
            <LuListTodo className="mt-1" size={25} color="#ffa6c9" />
            <p className="text-4xl ml-3 font-serif">To-Do</p>
          </div>
          <div className="flex flex-row pl-8 text-[#ffa6c9] mt-2">
            Quick to-do list
          </div>
        </article>
      </div>
    </div>
  );
};
