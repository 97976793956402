import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { CommonProvider } from "./contexts/CommonContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import ErrorPage from "./components/ErrorPage";
import { Projects } from "./pages/projects/Projects";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <Home title={"Login Page"} />,
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "projects",
    element: <ProtectedRoute comp={<Projects />} isLgn={true} />,
    errorElement: <ErrorPage />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CommonProvider>
      <RouterProvider router={router} />
      {/* <App /> */}
    </CommonProvider>
  </React.StrictMode>
);
